<template>
  <div id="works-page">
    <div class="works-page_title">
      <div class="works-page_text">PROJETS</div>
    </div>
    <div class="project-container">
      <div id="project-presentation">
        <div class="project-presentation-right">
          <div class="project-presentation_description">
            <p>Imaginé et conçu entièrement par moi-même, j'ai pensé mon portfolio
            en une visite originale représentative de mes compétences.
            l'ensemble accompagné d'animations rendant la visite plus
            agréable et fluide pour l'utilisateur.</p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/html-5.png" alt="" />
              <img class="icons-tools" src="../assets/img/css-3.png" alt="" />
              <img
                class="icons-tools"
                src="../assets/img/javascript.png"
                alt=""
              />
              <img class="icons-tools" src="../assets/img/vue.png" alt="" />
              <img class="icons-tools" src="../assets/img/gsap.png" alt="" />
              <a href="https://github.com/Mikael-W/Folio-V1_MW"
                ><img class="icons-tools" src="../assets/img/git.png" alt=""
              /></a>
            </div>
          </div>
          <div class="project-presentation_picture">
            <img class="project-img" src="../assets/img/folio_MW.png" alt="" />
          </div>
        </div>
      </div>
      <div id="project-presentation">
        <div class="project-presentation-left">
          <div class="project-presentation_picture">
            <img
              class="project-img"
              src="../assets/img/groupomania.png"
              alt=""
            />
          </div>
          <div class="project-presentation_description">
            <p>Projet complet présentant un réseau social d'entreprise inspiré
            de Facebook. Créer de la base de données jusqu'à l'interface utilisateur
            par mes soins</p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/html-5.png" alt="" />
              <img class="icons-tools" src="../assets/img/css-3.png" alt="" />
              <img
                class="icons-tools"
                src="../assets/img/javascript.png"
                alt=""
              />
              <img class="icons-tools" src="../assets/img/vue.png" alt="" />
              <img class="icons-tools" src="../assets/img/vuex.png" alt="" />
              <img class="icons-tools" src="../assets/img/node.png" alt="" />
              <img
                class="icons-tools"
                src="../assets/img/sequelize.png"
                alt=""
              />
              <img class="icons-tools" src="../assets/img/mysql.png" alt="" />
              <a href="https://github.com/Mikael-W/Groupomania"
                ><img class="icons-tools" src="../assets/img/git.png" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div id="project-presentation">
        <div class="project-presentation-right">
          <div class="project-presentation_description">
            <p>Superbe collaboration avec une jeune marque de produits de beauté suisse
              <a href="www.laerte-skincare.ch">LAERTE</a> créee des produits de beauté bio
              d'exception. J'ai crée cette 'teasing page' et élaborer les animations dans un esprit fluide et discret
             Site définitif à venir !
            </p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/html-5.png" alt="" />
              <img class="icons-tools" src="../assets/img/css-3.png" alt="" />
              <img
                class="icons-tools"
                src="../assets/img/javascript.png"
                alt=""
              />
              <img class="icons-tools" src="../assets/img/gsap.png" alt="" />
            </div>
          </div>
          <a href="www.laerte-skincare.ch" class="project-presentation_picture">
            <img
              class="project-img"
              src="../assets/img/laerte.png"
              alt="laerte-skincare.ch"
            />
          </a>
        </div>
      </div>
      <div id="project-presentation">
        <div class="project-presentation-left">
          <div class="project-presentation_picture">
            <img class="project-img" src="../assets/img/sopekocko.png" alt="" />
          </div>
          <div class="project-presentation_description">
            <p>De l'autre côté de l'écran, creation d'une API.
                De la base données au serveur en passant par sa sécurisation.
                Excellent projet pour un site de comparaison de sauces piquantes.</p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/node.png" alt="" />
              <img
                class="icons-tools"
                src="../assets/img/mongoose.png"
                alt=""
              />
              <img class="icons-tools" src="../assets/img/mongodb.png" alt="" />
              <a href="https://github.com/Mikael-W/Sopekocko"
                ><img class="icons-tools" src="../assets/img/git.png" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div id="project-presentation">
        <div class="project-presentation-right">
          <div class="project-presentation_description">
            <p>Developpement de l'interface utilisateur
            (front-end) d'un site e-commerce avec
            connexion à l'API (backend) et tests unitaires</p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/html-5.png" alt="" />
              <img class="icons-tools" src="../assets/img/css-3.png" alt="" />
              <img
                class="icons-tools"
                src="../assets/img/javascript.png"
                alt=""
              />
              <img
                class="icons-tools"
                src="../assets/img/bootstrap.png"
                alt=""
              />
              <a href="https://github.com/Mikael-W/Orinoco"
                ><img class="icons-tools" src="../assets/img/git.png" alt=""
              /></a>
            </div>
          </div>
          <div class="project-presentation_picture">
            <img class="project-img" src="../assets/img/orinoco.png" alt="" />
          </div>
        </div>
      </div>
      <div id="project-presentation">
        <div class="project-presentation-left">
          <div class="project-presentation_picture">
            <img
              class="project-img"
              src="../assets/img/lachouetteagence.png"
              alt=""
            />
          </div>
          <div class="project-presentation_description">
            <p>Optimisation SEO et de l'accessibilité d'un projet, recherche et étude
            des mots clés, amélioration de la vitesse de chargement. Mise en conformité
            de l'accessibilitée selon les normes wcag.</p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/html-5.png" alt="" />
              <img class="icons-tools" src="../assets/img/css-3.png" alt="" />
              <img
                class="icons-tools"
                src="../assets/img/bootstrap.png"
                alt=""
              />
              <a href="https://github.com/Mikael-W/LACHOUETTEAGENCE-SEO"
                ><img class="icons-tools" src="../assets/img/git.png" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div id="project-presentation">
        <div class="project-presentation-right">
          <div class="project-presentation_description">
            <p>intégration d'une maquette d'application mobile 
            proposant un service de commande en ligne de plats avec quelques animations</p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/html-5.png" alt="" />
              <img class="icons-tools" src="../assets/img/css-3.png" alt="" />
              <img class="icons-tools" src="../assets/img/sass.png" alt="" />
              <a href="hhttps://github.com/Mikael-W/WAWRZICZNYMIKAEL_3_03122020"
                ><img class="icons-tools" src="../assets/img/git.png" alt=""
              /></a>
            </div>
          </div>
          <a href="https://ohmyfood-bym.com/" class="project-presentation_picture">
            <img class="project-img" src="../assets/img/ohmyfood.png" alt="" />
          </a>
        </div>
      </div>
      <div id="project-presentation">
        <div class="project-presentation-left">
          <div class="project-presentation_picture">
            <img class="project-img" src="../assets/img/reservia.png" alt="" />
          </div>
          <div class="project-presentation_description">
            <p>Intégration d'une maquette d'un site de réservation de chambres
            d'hôtels et particulières en ligne</p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/html-5.png" alt="" />
              <img class="icons-tools" src="../assets/img/css-3.png" alt="" />
              <a href="https://github.com/Mikael-W/WAWRZICZNYMIKAEL_02_05112020"
                ><img class="icons-tools" src="../assets/img/git.png" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  name: "works",
};
</script>

<style scoped>
#works-page {
  position: relative;
  width: 100vw;
  height: 100vh;
}
.project-container {
  width: 96vw;
  height: 100vh;
  overflow: scroll;
  margin-left: 4vw;
}
.works-page_title {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  width: 4vw;
  height: 100vh;
  background: white;
  border-right: 3px solid #106db0;
  font-family: INKFERNO, sans-serif;
}
.works-page_text {
  display: flex;
    transform: rotate(-90deg);
    font-size: 3vw;
    color:#106db0; 
    margin-left:0.5vw;
}
.project-presentation-left,
.project-presentation-right {
  display: flex;
  width: 100%;
  height: 50vh;
}
.project-presentation_picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50vh;
  background: white;
}
.project-img {
  width: 80%;
  height: 75%;
  object-fit: fill;
}
.project-presentation_description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Podkova", serif;
  color: white;
  width: 50%;
  height: 50vh;
  background: #106db0;
}
.icons-tools_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.icons-tools {
  width: 2vw;
  margin: 0 1rem;
}
a{
  text-decoration: none;
}
p{
    font-size: 2vw;
    padding: 0 1.5vw;
    text-align: center;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .works-page_title {
    top: 0;
    bottom: 95vh;
    width: 100vw;
    height: 5vh;
    border: none;
  }
  .works-page_text {
    transform: none;
    font-size: 3vh;
  }
  .project-presentation-left{
      flex-direction: column;
      width: 91vw;
      height: 100vh;
      margin:0
  } 
  .project-presentation-right{
      flex-direction: column-reverse;
      width: 91vw;
      height: 100vh;
      margin:0
  }
  .project-presentation_picture{
      width: 91vw;
      height: 50vh;
  }
  .project-presentation_description {
      position: relative;
      width: 92vw;
      height: 50vh;
  }
  #project-presentation{
    height: 100vh;
  }
  .project-container{
      display: flex;
      overflow: scroll;
      width: auto;
      height: 100vh;
      margin:0;
  }
  .project-img{
    width: 80vw;
  }
  p{
    font-size: 5vw;
  }
  .icons-tools{
    width: 3.5vw;
  }
@media screen and (max-width: 425px) {
  .project-img{
    width: 80vw;
    height:25vh;
  }
  .project-presentation_picture{
      height: 50vh;
  }
  p{
    font-size: 5vw;
    padding:0.5rem;
    text-align: center;
  }
  .icons-tools{
    width: 3vw;
  }
  .icons-tools {
  margin: 0 0.5rem;
}
}
}
</style>